var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('h2',[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("Routes.editCategory"))+" ")],1)]),_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppHelpDesk.categoryColumns.name'),"rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppHelpDesk.categoryColumns.name'),"label-for":"name","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"disabled":!_vm.$can('write', 'MesaAyuda')},model:{value:(_vm.categoria.nombre),callback:function ($$v) {_vm.$set(_vm.categoria, "nombre", $$v)},expression:"categoria.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppHelpDesk.categoryColumns.abbreviation'),"rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppHelpDesk.categoryColumns.abbreviation'),"label-for":"name","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"disabled":!_vm.$can('write', 'MesaAyuda')},model:{value:(_vm.categoria.abreviatura),callback:function ($$v) {_vm.$set(_vm.categoria, "abreviatura", $$v)},expression:"categoria.abreviatura"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('AppHelpDesk.categoryColumns.parentCategoria'),"label-for":"assigned"}},[_c('v-select',{attrs:{"id":"assigned","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nombre","options":_vm.categories,"reduce":function (option) { return option.ticketCategoriaId; },"disabled":!_vm.$can('write', 'MesaAyuda')},model:{value:(_vm.categoria.categoriaPadreId),callback:function ($$v) {_vm.$set(_vm.categoria, "categoriaPadreId", $$v)},expression:"categoria.categoriaPadreId"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('AppHelpDesk.categoryDescription'),"rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppHelpDesk.categoryDescription'),"label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","rows":"3","placeholder":"La computadora no enciende, ademas de que se calienta mucho","disabled":!_vm.$can('write', 'MesaAyuda')},model:{value:(_vm.categoria.descripcion),callback:function ($$v) {_vm.$set(_vm.categoria, "descripcion", $$v)},expression:"categoria.descripcion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),(_vm.$can('write', 'MesaAyuda'))?_c('b-row',{staticClass:"float-right mt-2 mb-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[(_vm.edditingCategory)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("Lists.Edit"))+" ")],1)],1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }